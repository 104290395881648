import axios from 'axios';

export default async function subscribe({ email }) {

  console.log('Attempt to post')
  try {
    await axios.post('https://8noycg7loi.execute-api.us-east-1.amazonaws.com/default/siteSubscription',{ email })
    console.log('Success')
} catch (e) {
    console.log('Failed to post', e)
}
}

