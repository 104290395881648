import React from 'react';

class Services extends React.Component {
  render() {
  	return (
        <section className="section pt-5" id="services">
            <div className="container">
                <div className="row">
                    <div className="col-lg-9 offset-lg-2">
                        <h1 className="section-title text-center">Our Services</h1>
                        <div className="section-title-border margin-t-20"></div>
                        <p className="section-subtitle text-muted text-center padding-t-30 font-secondary">
                            We craft products, services and experiences to help you evolve your business and provide value to your users
                        </p>
                    </div>
                </div>
                <div className="row margin-t-30">
                    <div className="col-lg-4 margin-t-20">
                        <div className="services-box text-center hover-effect">
                            <i className="pe-7s-diamond text-custom"></i>
                            <h4 className="padding-t-15">Quality First</h4>
                            <p className="padding-t-15 text-muted">We take pride in our work, creating software that is well architected and executed.</p>
                        </div>
                    </div>
                    <div className="col-lg-4 margin-t-20">
                        <div className="services-box text-center hover-effect">
                            <i className="pe-7s-display2 text-custom"></i>
                            <h4 className="padding-t-15">User Centric</h4>
                            <p className="padding-t-15 text-muted">Your users are the ones that matter, we aim for software that is intutive and easy to understand</p>
                        </div>
                    </div>
                    <div className="col-lg-4 margin-t-20">
                        <div className="services-box text-center hover-effect">
                            <i className="pe-7s-piggy text-custom"></i>
                            <h4 className="padding-t-15">Cost Effiecient</h4>
                            <p className="padding-t-15 text-muted">Outsourcing work shouldn't be expensive, you cut costs without compromising the end result</p>
                        </div>
                    </div>
                </div>
                <div className="row margin-t-30">
                    <div className="col-lg-4 margin-t-20">
                        <div className="services-box text-center hover-effect">
                            <i className="pe-7s-science text-custom"></i>
                            <h4 className="padding-t-15">Cutting Edge</h4>
                            <p className="padding-t-15 text-muted">We invest in technology that is here to stay and aligns with the latest market trends</p>
                        </div>
                    </div>
                    <div className="col-lg-4 margin-t-20">
                        <div className="services-box text-center hover-effect">
                            <i className="pe-7s-news-paper text-custom"></i>
                            <h4 className="padding-t-15">Awesome Support</h4>
                            <p className="padding-t-15 text-muted">Building software comes hand to hand with continuous support, we got you covered</p>
                        </div>
                    </div>
                    <div className="col-lg-4 margin-t-20">
                        <div className="services-box text-center hover-effect">
                            <i className="pe-7s-plane text-custom"></i>
                            <h4 className="padding-t-15">Lean &amp; Flexible</h4>
                            <p className="padding-t-15 text-muted">You don't need the whole airplane to fly, software that creates value from the start</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
  	);
  }
}
export default Services;