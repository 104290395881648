import React from 'react';
import Navbar from './components/Navbar';
import Services from './components/Services';
import Features from './components/Features';
import Descriptions from './components/Descriptions';
import Pricing from './components/Pricing';
import Team from './components/Team';
import Process from './components/Process';
import Testi from './components/Testi';
import Started from './components/Started';
import Blog from './components/Blog';
import Contact from './components/Contact';
import SocialMedia from './components/SocialMedia';
import Footer from './components/Footer';
import FooterLinks from './components/FooterLinks';
import Particles from 'react-particles-js';
import Aux from './hoc/Aux_';

function HomeNine (){
  
    var mystyle = { position: 'absolute' };

    var bkg1 = {
        backgroundImage: 'url(images/wave-shape/wave1.png)',
      };
      var bkg2 = {
          backgroundImage: 'url(images/wave-shape/wave2.png)',
      };
      var bkg3 = {
          backgroundImage: 'url(images/wave-shape/wave3.png',
      };

  	return (
        <Aux>
                {/* Navbar Component*/}
                
                <Navbar />
                <section className="section bg-home height-100vh" id="home">
                <div className="bg-overlay"></div>
                    <div className="display-table" >
                        <div className="display-table-cell" >
                            <div className="container slidero">
                            
                                <div className="row">
                                    <div className="text-center subscribe-form margin-t-30">
                                    <div className="col-lg-8 offset-lg-2 text-white text-center">
                                        <h1 className="home-title">We help startups launch their products</h1>
                                    </div>    
                                        <form action="#">
                                                <input type="text" placeholder="Email" />
                                                <button type="submit" className="btn-custom">Subscribe</button>
                                        </form>
                                        {/* <p className="padding-t-15 home-desc">Etiam sed.Interdum consequat proin vestibulum className at a euismod mus luctus quam.Lorem ipsum dolor sit amet, consectetur adipisicing eli.</p>
                                        <p className="play-shadow margin-t-30 margin-l-r-auto"> <Link to="http://vimeo.com/99025203"  className="play-btn video-play-icon"><i className="mdi mdi-play text-center"></i></Link></p>   */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                     
                    <Particles style={mystyle}
                        params={{
                            "particles": {
                                "number": {
                                    "value": 200
                                },
                                "size": {
                                    "value": 4
                                }
                            },
                            "interactivity": {
                                "events": {
                                    "onhover": {
                                        "enable": true,
                                        "mode": "repulse"
                                    }
                                }
                            }
                        }} />

                    <div className="wave-effect wave-anim">
                        <div className="waves-shape shape-one">
                            <div className="wave wave-one" style={bkg1}></div>
                        </div>
                        <div className="waves-shape shape-two">
                            <div className="wave wave-two" style={bkg2}></div>
                        </div>
                        <div className="waves-shape shape-three">
                            <div className="wave wave-three" style={bkg3}></div>
                        </div>
                    </div>
                      
                </section>

                {/* Services Component*/}
                <Services />

                {/* Features Component*/}
                <Features />
                
                {/* Descriptions Component*/}
                <Descriptions />

                {/* Pricing Component*/}
                <Pricing />

                {/* Team Component*/}
                <Team />

                {/* Process Component*/}
                <Process />

                {/* Testi Component*/}
                <Testi />

                {/* Started Component*/}
                <Started />

                {/* Blog Component*/}
                <Blog />

                {/* Contact Component*/}
                <Contact />

                {/* SocialMedia Component*/}
                <SocialMedia />
                
                {/* Footer Component*/}
                <Footer />

                {/* FooterLinks Component*/}
                <FooterLinks />

                {/* Switcher Component*/}
               {/* <Switcher />  */}
        </Aux>
  	);
  }


export default HomeNine;