import React , {useState} from 'react';
import Navbar from './components/Navbar';
import Services from './components/Services';
import Features from './components/Features';
// import Descriptions from './components/Descriptions';
// import Pricing from './components/Pricing';
// import Team from './components/Team';
import Process from './components/Process';
import Started from './components/Started';
// import Blog from './components/Blog';
import Contact from './components/Contact';
import SocialMedia from './components/SocialMedia';
// import Footer from './components/Footer';
// import FooterLinks from './components/FooterLinks';
import Aux from './hoc/Aux_';
import Particles from 'react-particles-js';
import subscribe from './helpers/mailchimp';

function HomeOne () {

    var bkg1 = {
        backgroundImage: 'url(images/wave-shape/wave1.png)',
    };
    var bkg2 = {
          backgroundImage: 'url(images/wave-shape/wave2.png)',
    };
    var bkg3 = {
        backgroundImage: 'url(images/wave-shape/wave3.png',
    };
    var mystyle = {position:'absolute'};

 
    const [email, setEmail] = useState("");
    const [submitted, isSubmited] = useState(false);
    const [emailError, setEmailError] = useState('');


    const validate = (email) => {
 
        if(!email.includes('@') || !email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i) || email.length===0){
            return false            
        }
        return true
    };

    const handleChange = (e) =>{
        e.preventDefault();
        setEmail (e.target.value); 
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        if (validate(email)) {
            isSubmited(true)
            setEmailError('')
            subscribe({email})
        } else {
            setEmailError('Invalid Email')
        }

    }


  	return (
        <Aux>
                {/* Navbar Component*/}
                <Navbar />

                <Navbar />
                <section className="section bg-home height-100vh" id="home">
                <div className="bg-overlay"></div>
                    <div className="" >
                        <div className="display-table-cell" >
                            <div className="container slidero">
                                    <div className="text-center subscribe-form margin-t-30">
                                    <div className="col-lg-8 offset-lg-2 text-white text-center">
                                        <h1 className="home-title">We build great software for companies</h1>
                                    </div>                               
                                        {submitted ? <div className="email-success">Great! You 'll hear from us very soon!</div>:<form action="" method="POST"  
                                            onSubmit={handleSubmit}>
                                            <input type="email" id="email" placeholder="An email to reach you"  onChange={handleChange}/>
                                            <div className="invalid-email">
                                                {emailError}
                                            </div>
                                            <button type="submit" className="btn-custom" >Learn more</button>
                                            </form>
                                        }

                                        {/* <p className="padding-t-15 home-desc">Etiam sed.Interdum consequat proin vestibulum className at a euismod mus luctus quam.Lorem ipsum dolor sit amet, consectetur adipisicing eli.</p>
                                        <p className="play-shadow margin-t-30 margin-l-r-auto"> <Link to="http://vimeo.com/99025203"  className="play-btn video-play-icon"><i className="mdi mdi-play text-center"></i></Link></p>   */}
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div className="wave-effect wave-anim">
                        <div className="waves-shape shape-one">
                            <div className="wave wave-one" style={bkg1}></div>
                        </div>
                        <div className="waves-shape shape-two">
                            <div className="wave wave-two" style={bkg2}></div>
                        </div>
                        <div className="waves-shape shape-three">
                            <div className="wave wave-three" style={bkg3}></div>
                        </div>
                    </div>

                <div id="fibers">
                    <Particles 
                    style={mystyle}
                            params={{
                                "particles": {
                                    "number": {
                                        "value": 200
                                    },
                                    "size": {
                                        "value": 4
                                    }
                                },
                                "interactivity": {
                                    "events": {
                                        "onhover": {
                                            "enable": true,
                                            "mode": "repulse"
                                         }       
                                     }
                             }
                   }} />  

                </div>      
                         
                                        
                </section>

                {/* Services Component*/}
                <Services />

                {/* Features Component*/}
                <Features />
                
                {/* Descriptions Component*/}
                {/* <Descriptions /> */}

                {/* Pricing Component*/}
                {/* <Pricing /> */}

                {/* Team Component*/}
                {/* <Team /> */}

                {/* Process Component*/}
                <Process />

                {/* Testi Component*/}
                 {/* <Testi />*/}

                {/* Started Component*/}
                <Started />

                {/* Blog Component*/}
                {/* <Blog /> */}

                {/* Contact Component*/}
                <Contact />

                {/* SocialMedia Component*/}
                <SocialMedia />
                
                {/* Footer Component*/}
                {/* <Footer /> */}

                {/* FooterLinks Component*/}
                {/* <FooterLinks /> */}

                {/* Switcher Component*/}
               {/* <Switcher />  */}
               
        </Aux>
  	);
  }


export default HomeOne;