import React,{useState} from 'react';
import subscribe from '../helpers/mailchimp';


function Contact() {    

    // const[message, setMessage] = useState("");
    const [email, setEmail] = useState("");
    const [emailError, setEmailError] = useState('');
    
    const validate = (email) => { 
        if(!email.includes('@') || !email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i) || email.length===0){
            return false            
        }
        return true
    };

    const handleChange = (e) =>{
        e.preventDefault();
        setEmail (e.target.value); 
        // setMessage(e.target.value); 
    }

    const handleSubmit = (e) =>{
        e.preventDefault();
        if(validate(email)){
            setEmailError('')
            // setMessage({message})
            console.log('CONTact form email', email)
            subscribe({email});
        }
        else{
            setEmailError('Invalid Email')
        }
    }

  	return (
        <section className="section" id="contact">
        <div className="container">
            <div className="row">
                <div className="col-lg-8 offset-lg-2">
                    <h1 className="section-title text-center">Get a FREE Consulting Session</h1>
                    <div className="section-title-border margin-t-20"></div>
                    <p className="section-subtitle text-muted text-center font-secondary padding-t-30">We are here to take your business to the next level.</p>
                </div>
            </div>
            <div className="row">
                {/* <div className="col-lg-4"> */}
                </div>
                <div className="col-lg-8">
                    <div className="custom-form mt-4 pt-4">
                        <div id="message"></div>
                        <form method="post" action="home-one" name="contact-form" id="contact-form" onSubmit={handleSubmit}>
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="form-group mt-2">
                                        <input name="name" id="name" type="text" className="form-control" placeholder="Your name*" required/>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="form-group mt-2">
                                        <input name="email" id="email" type="email" className="form-control" placeholder="Your email*" onChange={handleChange} required/>
                                        <div style={{fontSize:25, color:"red"}}>
                                                {emailError}
                                        </div>
                                    </div>
                                </div>                                
                            </div>
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="form-group mt-2">
                                        <input type="text" className="form-control" id="subject" placeholder="Your Subject.." />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="form-group mt-2">
                                        <textarea name="comments" id="comments" rows="4" className="form-control" placeholder="Your message..."></textarea>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-12 text-right">
                                    <input type="submit" id="submit" name="send" className="submitBnt btn btn-custom" value="Send Message" />
                                    <div id="simple-msg"></div>
                                </div>
                            </div>
                        </form>
                    </div>  
                </div>
            </div>
       {/* / </div> */}
    </section> 
  	);
  }

export default Contact;